import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import '../scss/NuHeritageQuote2.scss';

function NuHeritageQuote2() {
  const data = useStaticQuery(graphql`
    query MyQuery34 {
      allContentfulQuote(filter: { title: { eq: "Nu Heritage Quote 2" } }) {
        edges {
          node {
            author
            quoteText
            title
            profession
          }
        }
      }
    }
  `);

  const quoteNode = data.allContentfulQuote.edges[0].node;

  return (
    <div className="quote5_container">
      <div className="quote5_text1_container">
        <p className="quote5_text">
          {quoteNode.quoteText}
          <br />
          <br />
        </p>
      </div>
      <div className="quote5_type_container">
        <p className="quote5_type">{quoteNode.profession}</p>
      </div>
      <div className="quote5_subtitle_container">
        <p className="author5_subtitle">{quoteNode.author}</p>
      </div>
    </div>
  );
}

export default NuHeritageQuote2;
