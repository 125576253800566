import * as React from "react"
import FeatureBar from "./FeatureBar"
import FeatureBarPink from "./FeatureBarPink"
import Footer from "./Footer"
import "./Layout.css"
import NavBar from "./Nav/NavBar"
import NewsletterBar from "./NewsletterBar"
import CookieBanner from './CookieBanner'
import HeroImageNuHeritage from "../components/NuHeritagePage/HeroImageNuHeritage"

const LayoutNu = ({ children }) => {


  return (
    <div>
      <CookieBanner />
      <NavBar />
      <FeatureBarPink />
      <FeatureBar />
      {/* <div style={{ height: "100px" }} /> */}
      <HeroImageNuHeritage />
      
      {children}

      <NewsletterBar />
      <Footer />
    </div> 
  )
}


export default LayoutNu