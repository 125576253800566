import * as React from "react"

import LayoutNu from "../components/LayoutNu"
import NuHeritageText1 from "../components/NuHeritagePage/NuHeritageText1"
import NuHeritagePicture1 from "../components/NuHeritagePage/NuHeritagePicture1"
import NuHeritagePicture2 from "../components/NuHeritagePage/NuHeritagePicture2"
import NuHeritagePicture3 from "../components/NuHeritagePage/NuHeritagePicture3"
import NuHeritageText2 from "../components/NuHeritagePage/NuHeritageText2"
import NuHeritageText3 from "../components/NuHeritagePage/NuHeritageText3"
import NuHeritageQuote1 from "../components/NuHeritagePage/NuHeritageQuote1"
import NuHeritageQuote2 from "../components/NuHeritagePage/NuHeritageQuote2"
import NuHeritageQuote3 from "../components/NuHeritagePage/NuHeritageQuote3"
import DropdownExample from "../components/NuHeritagePage/Dropdown"

function NuHeritagePage({ data }) {
  return (
    <LayoutNu>
      <NuHeritageText1/>
      <NuHeritagePicture1/>
      <NuHeritageQuote1/>
      <NuHeritageText2/>
      <NuHeritagePicture2/>
      <NuHeritageQuote2/>
      <NuHeritageText3/>
      <NuHeritagePicture3/>
      <NuHeritageQuote3/>
      {/* <DropdownExample/> */}
    </LayoutNu>
  )
}

export default NuHeritagePage