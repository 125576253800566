import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import '../scss/NuHeritageQuote1.scss';

function NuHeritageQuote1() {
  const data = useStaticQuery(graphql`
    query MyQuery31 {
      allContentfulQuote(filter: { title: { eq: "Nu Heritage Quote 1" } }) {
        edges {
          node {
            author
            quoteText
            title
            profession
          }
        }
      }
    }
  `);

  const quoteNode = data.allContentfulQuote.edges[0].node;

  return (
    <div className="quote4_container">
      <div className="quote4_logo_container">
        <div className="quote4_logo_line"></div>
        <h1 className="quote4_logo_text">
          <span className="quote_2_text_pink">F.</span>
          <span className="quote_2_text_black">K</span>
        </h1>
        <div className="quote4_logo_line"></div>
      </div>
      <div className="quote4_text1_container">
        <p className="quote4_text">
          {quoteNode.quoteText}
          <br />
          <br />
        </p>
      </div>
      <div className="quote4_type_container">
        <p className="quote4_type">{quoteNode.profession}</p>
      </div>
      <div className="quote4_subtitle_container">
        <p className="author4_subtitle">{quoteNode.author}</p>
      </div>
    </div>
  );
}

export default NuHeritageQuote1;
