import React, { useEffect } from "react";
import "../scss/NuHeritageText3.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function NuHeritageText3() {
  const data = useStaticQuery(graphql`
    query MyQuery35 {
      allContentfulTextBlock(
        filter: { id: { eq: "0c75299f-489a-58c7-9575-0b362592c6c9" } }
      ) {
        edges {
          node {
            title
            bodyText {
              raw
            }
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <div className="contentFeatureNuHerText3">
      <div className={"textContainerNuHerText3"}>
        <p className={"titleNuHerText3"}>{title}</p>
        <p className="bodyNuHerText3">
          {documentToReactComponents(JSON.parse(bodyText.raw), options)}
        </p>
      </div>
    </div>
  );
}
