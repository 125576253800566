import React, { useEffect } from "react";
import "../scss/NuHeritageText2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function NuHeritageText1() {
  const data = useStaticQuery(graphql`
    query MyQuery32 {
      allContentfulTextBlock(
        filter: { id: { eq: "59da567a-a552-5491-87f2-ba0f60bb316f" } }
      ) {
        edges {
          node {
            title
            bodyText {
              raw
            }
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <div className="contentFeatureNuHerText2">
      <div className={"textContainerNuHerText2"}>
        <p className={"titleNuHerText2"}>{title}</p>
        <p className="bodyNuHerText2">
          {documentToReactComponents(JSON.parse(bodyText.raw), options)}
        </p>
      </div>
    </div>
  );
}
