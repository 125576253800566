import React, { useEffect } from "react";
import "../scss/NuHeritageText1.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export default function NuHeritageText1() {
  const data = useStaticQuery(graphql`
    query MyQuery29 {
      allContentfulTextBlock(
        filter: { id: { eq: "81d56433-8c05-51c6-a3a3-48dcc00be9e3" } }
      ) {
        edges {
          node {
            title
            bodyText {
              raw
            }
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const {
    title,
    bodyText,
  } = data.allContentfulTextBlock.edges[0].node;

  const options = {
    renderText: (text) => {
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);
    },
  };

  return (
    <div className="contentFeaturePurposePrinciples">
      <div className={"textContainerPurposePrinciples"}>
        <p className={"titlePurposePrinciples"}>{title}</p>
        <p className="bodyPurposePrinciples">
          {documentToReactComponents(JSON.parse(bodyText.raw), options)}
        </p>
      </div>
    </div>
  );
}
