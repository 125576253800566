import React, { useEffect } from "react";
import "../scss/NuHeritagePicture1.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";

export default function NuHeritagePicture1() {
  const data = useStaticQuery(graphql`
  query MyQuery30 {
    allContentfulImageBlock(filter: { name: { eq: "Nu Heritage Pic 1" } }) {
      edges {
        node {
          image {
            image {
              file {
                url
              }
            }
          }
          name
        }
      }
    }
  }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureNuHerPic1">
      <div data-aos="fade-up" className="boardTextContainerNuHerPic1 fade-in">
        <div className={"displayBoardContainerNuHerPic1"}>
          <div className="displayBoardNuHerPic1"></div>
        </div>
      </div>

      <div className={"textContainerNuHerPic1"}>
        {/* <p className={'titleNuHerPic1'}>{data.allContentfulFlexStyleBody.edges[9].node.title}</p> */}
      </div>
      <div className={"imageContainerNuHerPic1"}>
        <img
          data-aos="fade-up"
          className={"bigImagNuHerPic1"}
          alt={""}
          key={""}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
        />
      </div>
    </div>
  );
}
